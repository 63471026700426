<template>
    <div :class="{form: !isComment }">
        <div v-if="label" class="px-3 pt-3">
            <h6 for="">
                {{ label }}
            </h6>
        </div>
        <quill-editor
            ref="quill"
            v-model="formData.message.text"
            v-validate="'required'"
            name="content"
            :options="editorOptions"
        />
        <small class="content-error text-danger">{{ errors.first("content") }}</small>
        <div v-if="!isComment" class="images-container px-4 py-2">
            <feed-gallery
                ref="gallery"
                v-model="formData.files"
                @removed="removeFile"
            />
        </div>
        <div class="feed-toolbar">
            <span v-if="!isComment" @click="$refs.gallery.openUploader()">
                <i class="fa fa-image" />
            </span>
            <button
                v-if="!isComment"
                class="btn btn-danger ml-2"
                :disabled="isLoading"
                @click.prevent="$emit('cancel')"
            >
                Cancel
            </button>
            <button v-if="isComment" class="btn btn-primary ml-3" @click.prevent="$emit('load-comments')">
                Load Comments
            </button>
            <button class="btn btn-success ml-2" :disabled="isLoading" @click.prevent="save()">
                Save
            </button>
        </div>
    </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import FeedGallery from "./feed-gallery";

import "quill-mention";

export default {
    components: {
        quillEditor,
        FeedGallery
    },
    props: {
        endpoint: {
            type: String,
            default: ""
        },
        parentData: {
            type: Object,
            default() {
                return {}
            }
        },
        label:{
            type: String,
            default: ""
        },
        mode:{
            type: String,
            default: "message"
        },
        feedData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            formData: {
                leads_uuid: "",
                files: [],
                verb: "lead",
                message: {

                }
            },
            isLoading: false
        }
    },
    computed: {
        editor() {
            return this.$refs.quill;
        },
        isComment() {
            return this.mode == "comment";
        },
        commentEndpoint() {
            const endpoint = "messages";
            return this.mode == "comment" ? `${endpoint}/${this.parentData.id}/comments` : this.endpoint;
        },
        successMessage() {
            return this.isComment ? "New comment created" : "New message added";
        },
        editorOptions() {
            const self = this;
            return {
                placeholder: "Add a new text...",
                modules: {
                    toolbar: false,
                    keyboard: {
                        bindings: [
                            {
                                key: "enter",
                                ctrlKey: true,
                                handler() {
                                    self.save()
                                }
                            }
                        ]
                    },
                    mention: {
                        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                        mentionDenotationChars: ["@", "#"],
                        source: async function(searchTerm, renderList) {
                            const users = await axios("/users").then(({ data }) => {
                                return data.map(user => {
                                    return { id: user.id, value: user.displayname, userName: user.firstname + " " + user.lastname }
                                })
                            })

                            return renderList(users)
                        }
                    }
                }
            }
        }
    },
    watch: {
        feedData: {
            handler() {
                this.clearForm();
                const data = { ...this.feedData };
                data.message = typeof data.message == "string" ? JSON.parse(data.message) : data.message || {};
                data.files = [];
                this.formData = { ...this.formData, ...data };
            },
            immediate: true
        }
    },
    methods: {
        updateList(file) {
            this.formData.files.push(...file);
        },
        removeFile(file) {
            this.formData.files = this.formData.files.filter(localFile => localFile.url != file.url);
        },

        clearForm() {
            this.formData.message = {}
            this.formData.files = [];
            setTimeout(() => {
                this.errors.clear()
            });
        },


        formatDataFunc() {
            return this.isComment ? this.prepareComment() : this.prepareMessage();
        },

        prepareMessage() {
            const formData = { ...this.formData }
            formData.leads_uuid = this.parentData.uuid;
            formData.files = this.getGalleryFiles(formData.files);
            return formData;
        },

        prepareComment() {
            const formData = { ...this.formData }
            formData.message = formData.message.text;
            return formData;
        },

        async save() {
            const isValid = await this.$validator.validateAll()
            let method = "POST";
            let url = this.commentEndpoint;
            let formData = {}

            if (!isValid) {
                this.focusFirstError(false)
                return;
            }

            formData = this.formatDataFunc();

            if (formData.id) {
                method = "PUT"
                url += `/${formData.id}`
            }

            if (!this.isLoading) {
                this.isLoading = true;
                axios({
                    url: url,
                    data: formData,
                    method: method
                })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.$notify({
                            title: "Success",
                            text: this.successMessage,
                            type: "success"
                        });

                        this.$emit("saved", data)
                        this.clearForm();
                    })
                    .catch((error) => {
                        this.$notify({
                            title: "Error",
                            text: error.response ? error.response.data.errors.message : "Error",
                            type: "error"
                        });
                        return false;
                    })
                    .finally((result) => {
                        this.isLoading = false;
                        return result;
                    })
            }
        },

        getGalleryFiles(files) {
            return files.map(file => {
                const realFile = file.response && file.response.length ? file.response[0] : file;
                return this.formatFileSystem(realFile);
            });
        }
    }
}
</script>

<style lang="scss">
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
        border-top: 0px;
        border: none;
    }

    .ql-editor {
        padding: 12px 20px !important;
    }

    .form .ql-editor {
        min-height: 60px !important;
    }

    .ql-editor.ql-blank::before {
        padding-left: 5px;
    }

    .ql-container.ql-snow {
        border: none;
    }

    ul.ql-mention-list {
        border-radius: 4px;
        margin: 0 0 0 0;
        margin-top: 2px;
        padding: 0 0 0 0;
        list-style: none;
        border: 1px solid #eee;
        box-shadow: 4px 4px 4px #eee;
        background: white;
        z-index: 100;

        li {
            width: 100%;
            cursor: pointer;
            padding: 10px 15px;
            &:hover {
                background: rgba(30, 143, 255, 0.192);
            }
        }
    }

    .content-error {
        padding-left: 20px;
    }

    .feed-toolbar {
        display: flex;
        border-top: 1px solid #eee;
        margin: 0 15px;
        padding: 15px 0;
        justify-content: flex-end;
        position: relative;
    }
</style>
