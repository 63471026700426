
<template>
    <div :class="{'no-visible': !files.length }">
        <el-upload
            ref="uploader"
            list-type="picture-card"
            :on-change="handleChange"
            :action="endpoint"
            :headers="{ Authorization: token }"
            :file-list="files"
            :disabled="disabled"
        >
            <i slot="default" class="el-icon-plus" />
            <div slot="file" slot-scope="{file}">
                <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                >
                <span class="el-upload-list__item-actions">
                    <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                    >
                        <i class="el-icon-zoom-in" />
                    </span>
                    <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                    >
                        <i class="el-icon-download" />
                    </span>
                    <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                    >
                        <i class="el-icon-delete" />
                    </span>
                </span>
            </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            isLoading: false,
            files: [],
            baseURL: process.env.VUE_APP_BASE_API_URL,
            token: this.$store.state.User.token || Cookies.get("token")
        };
    },
    computed: {
        endpoint() {
            return this.baseURL + "/filesystem";
        }
    },
    watch: {
        value:{
            handler(values) {
                this.files = values;
            },
            immediate: true
        },
        files:{
            handler(files) {
                this.$emit("input", files);
            },
            immediate: true
        }
    },
    methods: {
        handleRemove(file) {
            this.$emit("removed", file);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
        openUploader() {
            this.$refs.uploader.$el.querySelector(".el-upload").click()
        },
        handleChange(_file, fileList) {
            this.files = fileList;
        }
    }
}
</script>


<style lang="scss" scoped>
.no-visible {
    width: 1px;
    height: 1px;
    opacity: 0;
}
</style>
